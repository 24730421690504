
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.colDevider{
  margin: 50px;
  width: 500px;
}

.icon{
  margin: 10px;
}


.icon svg{
  font-size: 38px;
  border-radius: 7px;
  padding: 8px;
  background-color: #006BA6;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.element {
 
  text-align: center;
  padding: 18px;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  width: fit-content;
  font-size: 20px;
}

.element:nth-child(1) {
  background-color: #0496FF;
}
.element:nth-child(2) {
  background-color: #006BA6;
}
.element:nth-child(3) {
  background-color: #0496FF;
}

.element.elementMiddle:nth-child(1) {
  background-color: #FFBC42;
}
.element.elementMiddle:nth-child(2) {
  background-color: #0496FF;
}
.element.elementMiddle:nth-child(3) {
  background-color: #D81159;
}


.leftElement{
  margin-right:100px
}

.rightElement{
  margin-left:100px
}

.rightElementHalf{
  margin-left: 50px;
}




@media screen and (max-width: 1224px) {
  .col {
    flex-direction: row;
    max-width: none;
  }

  .container {
    flex-direction: column;
  }

  .icon{
    margin: 7px;
  }
  .icon svg{
    font-size: 30px;
  }

  .element{
    font-size: 14px;
    width: min-content;
    padding: 10px;
    margin: 8px;
  }

  .leftElement{
    margin-right: 10px;
    margin-bottom: 100px;
  }
  
  .rightElement{
    margin-left: 10px;
    margin-top: 100px;
  }

  .rightElementHalf{
    margin-left: 10px;
    margin-top: 50px;
  }

}