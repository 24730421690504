
@font-face {
  font-family: 'Merriweather';
  src: url('fonts/Merriweather/Merriweather-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'DMSans';
  src: url('fonts/DMSans/DMSans.ttf') format('opentype');
}

:root {
  --blue: #043F53;
  --dark-blue: #1B3048;
  --darker-blue: #1E2021;
  --gray: #F7F7F7;
}

body {

  font-family: "DMSans";
  background-color: var(--gray);
}

main {
  min-height: calc(100vh - 6rem - 37px);
}

.header {

  color: white;
  background: var(--dark-blue);

  padding: 100px 200px 25px;
}

.header *{
  width: 600px;
}

.header h1{
  text-align: left;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 100;
  font-size: 3.2rem;
  line-height: 60px;
  letter-spacing: -2px;
  margin: 0;
}

.header .undertitle {
  margin: 0;
  font-size: 20px;
  padding-top: 20px;
  font-weight: 600;
  text-align: left;
  color: white;
  margin-bottom: 100px;
}




footer {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 15px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--dark-blue);

}


hr {
  margin: 50px 20px;
  border: none;
  height: 1px;
  background: #000;
  background: repeating-linear-gradient(90deg, #000, #000 6px, transparent 6px, transparent 12px);
}





@media screen and (max-width: 992px) {
  .undertitle {
    padding: 0;
    margin-bottom: 20px !important;
  }

  .header *{
    width: auto;
  }

  .header{
    padding: 50px 20px 25px;
  }
}


.App {
  text-align: center;
  color: white;
}

.background-image {
  position: absolute;
  opacity: 0.5;

}

footer {
  overflow: hidden;
}

.logo {
  width: 150px;
  padding-right: 32px;
}

.top-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-blue);
  padding: 0 32px;
}

.top-header .menu-button {
  font-size: 52px;
  display: none;
  padding: 15px;
}

.button {
  background: none;
  border: none;
  border-bottom: solid #1E2021 0.2rem;
  height: 4rem;
  font-weight: 900;
  padding: 0 1rem;
  font-size: 1.5rem;
  color: #1E2021;
  cursor: pointer;

  transition: 0.3s;
}

.button:hover {
  border-bottom: solid #1E2021 0.3rem;
}

.button:disabled {
  border-bottom: solid #7a7a7a 0.2rem;
  color: #484b4d;
  cursor: auto;
}



.navbar {
  font-size: 1.1rem;
  font-weight: 600;
  width: 100%;
  background-color: var(--dark-blue);
}

.navbar .logo-wrapper{
  margin-top: -13px;
}

.navbar .logo-wrapper img{
  padding: 0px 32px
}


.navbar ul li:not(.language) {

  margin: 0px 20px;
  z-index: 5;
}

.language {
  margin: 0px 25px;
  padding-bottom: 10px;
}

.language ul {
  list-style-type: none;
}

.language>div {
  padding-bottom: 0;
}

.navbar a{
  cursor: pointer;
  text-decoration: none;
  color: white;
}



.navbar a:hover,
.navbar a:focus,
.navbar a:active,
.navbar [aria-current=page] {
  text-decoration: underline;
}

.navbar a:active {
  
  text-decoration: underline;
  color: white;
}




.navbar .link {
  cursor: pointer;
  text-decoration: none;
  color: white;
}


.navbar .link:hover,
.navbar .link:focus,
.navbar .link:active {
  text-decoration: underline;
}

.navbar .link:active{
  
  text-decoration: underline;
}








.navbar>ul {
  list-style-type: none;
  padding: 0 32px;
  margin: 0;

  height: 6rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar .right {
  display: flex;
}

.navbar .left {
  display: flex;
  align-items: center;
}

.dropdown-content.visable {

  display: flex;
}




.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  padding: 24px 21px;
  font-size: 17px;
  z-index: 1;
  margin-top: 39px;
}

.dropdown-content a {
  margin: 10px 20px;
  font-size: 1.1rem;
  color: var(--dark-blue);

}

.dropdown.lng-selector .lng-selector-button {
  display: flex;
  align-items: center;


}

.lng-selector .dropdown-content {
  margin-left: -104px;
}

.dropdown.lng-selector .lng-selector-button path{
  stroke: white;


}







.card {
  position: relative;
    overflow: hidden;
  padding: 1.5rem;
  border-radius: 5px;
  background-color: white;
  width: 50%;

  font-weight: normal;
  font-size: 19px;
  line-height: 30px;
  
  color: var(--dark-blue);

  margin: 50px auto;
  height: fit-content;
}




.card h2 {
  color: var(--dark-blue);
  font-family: "Merriweather";
  font-style: normal;
  letter-spacing: -1px;
  margin: 0;
  font-size: 32px;
  font-weight: 100;
  line-height: 40px;
  
  margin-bottom: 10px;
}



.card .card-icon {
  width: 200px;
  margin: auto;
}

.card .card-admin {
  border-top: 1px solid #8A8A8A;
  margin-top: 10px;
  font-size: 15px;
  justify-content: space-between;
  display: flex;
}

.main{
  margin-bottom: 100px;
}

.main .slider {
  background-color: var(--dark-blue);
}

.slide {
  display: flex;
  text-align: center;
  padding-bottom: 50px;
}

.slide-header {
  font-size: 23px;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: white;
  color: var(--dark-blue);
  display: inherit;
  justify-content: center;
  align-items: center;

  margin: 35px 10px;
}





.main .slide-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.main .slide-images .slide-image img {
  width: 250px;
}

.main .slide-images .slide-image {
  background-color: white;
  border-radius: 5px;
  font-size: 20px;
  margin: 0px 50px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 210px;
}


.main .slide-images .slide-image.overflow {
  overflow: hidden;
}

.main .slide-images .slide-image.bottom {
  margin-top: 60px;
}

@media screen and (max-width: 992px) {
  .main .slide-images .slide-image {
    margin-top: 60px;
  }
}

.main .slide-images .slide-title {

  padding: 10px 0px;
  width: 250px;
  background-color: white;
  color: var(--dark-blue);
  margin: 0;
  font-size: 23px;
  font-weight: 500;
  border-radius: 5px;

}



.main .partners-wrapper {
  background-color: var(--dark-blue);
  padding-bottom: 15px;
}



.main .partners {

  width: 98%;
  align-items: center;
  padding: 16px 0px;
  margin: auto;
  margin-bottom: -88px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 11px;
  border-radius: 5px;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

}



.main .partners::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.main .partners img:first-child {
  margin-left: auto;
}

.main .partners img:last-child {
  margin-right: auto;
}

.main .partners-scroll {

  display: flex;
  min-height: min-content;
}


.main .partners img {
  width: 150px;
  padding: 25px;
  vertical-align: center;
  transition: all .2s ease-in-out;
}

.main .partners img:hover {
  width: 160px;
}

.main .quote {
  padding: 200px 15%;
  font-style: italic;
  font-size: 30px;
  text-align: center;


}

.main .workflow {
  width: 80%;
}







.about .team {
  background-color: white;
}

.about .team h2{
  padding: 50px;
}

.about .teamMembers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1600px;
  margin: auto auto;
}

.about .teamMember {
  margin: 15px 40px;
  width: 300px;

  height: min-content;
  background-color: white;

  text-align: left;
}

.about .teamMember img {
  align-self: center;
  width: inherit;
  background-color: var(--gray);
  height: 300px;
  overflow: hidden;
}

.about .teamMember .name {
  font-size: 25px;
  font-weight: 800;

  padding-top: 5px;
  padding-left: 10px;
  width: 85%;
}

.about .teamMember .role {
  padding: 0px;

  padding-top: 2px;
  color: rgb(84, 84, 84);
  padding-left: 10px;
  font-size: 18px;

}

.about .teamMember .info {
  position: relative;

}




.about .teamMember .showmore {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #cfcfcf;
}

.about .teamMember .dropdown {
  padding: 10px;
  background-color: white;
}






.about .teamMember .dropdown.show {
  display: block;
}

.about .teamMember .dropdown.notshow {
  display: none;
}


.about .teamMember .dropdown .title {
  font-size: 18px;
  font-weight: 600;
}

.about .teamMember .dropdown ul {
  text-align: left;
  display: inline-block;
}


.about h2 {
  margin: 0 60px;
  font-size: 50px;
  font-weight: 900;
}

.about .devider div:first-of-type {
  margin-right: 20px;
}

.about .devider div:last-of-type {
  margin-left: 20px;
}

.about .devider div {
  font-size: 24px;
  font-weight: 600;
  margin-top: 25px;
}

.about .devider p {
  margin: 0;
}

.about .devider {
  margin: 50px;
}

.about .devider.devider-2 {
  background-color: white;
  margin: 0;
  padding: 100px;
  margin-bottom: 100px;
}






.header .header-image {
  width: 172px;

}


.devider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.devider>* {
  width: 52%;
}



@media screen and (max-width: 992px) {
  .devider>* {
    width: 100%;
  }
}


.devider ul {
  text-align: left;

}

.integrations .devider ul li:before {
  content: "\2713\0020";
}

.integrations .devider ul {
  list-style-type: none;
}

.devider ul li {
  padding: 10px;
}


.content {
  text-align: left;
}

@media screen and (max-width: 992px) {
  .content {
    text-align: left;
  }
}


.ccm .image-wrapper {
  display: flex;
  justify-content: center;
}

.ccm .image {
  width: 80%;
}

.customers .partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}

.customers .partners div {
  margin: 50px 45px;
  width: 350px;
  height: 150px;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  background-color: white;
  border-radius: 5px;


}

.customers .partners img {
  align-self: center;
  width: 200px;
  padding: 25px;
  vertical-align: center;
}

.partners img.partner_logo{
  width: 400px;
}


.partners img.partner_ilustration{
  width: 400px;
  transition: all .2s ease-in-out;
}
.partners img.partner_ilustration:hover{
  width: 800px;
}


.partners .undertitle.devide{
  display: flex;
  justify-content: space-between;
  width: auto;
}

.partners .undertitle.devide > div{
  width: 40%;
}

.partners .undertitle.devide > div:last-child{
  padding: 90px 40px 0px;
}


.slick-dots-fw {
  position: relative !important;
  top: -10px
}


.slick-dots-fw li.slick-active button:before {
  color: white !important;
}

.slick-dots-fw li button:hover:before,
.slick-dots-fw li button:focus:before {
  color: white !important;
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}


.contact .card {
  width: 70%;
  max-width: 1200px;
  padding: 3rem;

}

.contact .card.seminar {
  width: 50%;
  max-width: 1200px;

}

.contact-form {
  display: flex;
  flex-wrap: wrap;
}


.contact-form-devider.textarea {
  width: 100%;
}

.contact-form-devider {
  width: 27rem;
}

.card.seminar .contact-form-devider {
  width: 35rem;
}

.card.seminar .seminar-logo{
  width: 100%;
}

.card .disabled{
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 11%);
  
}

.card .booked{
  transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 34px;
    position: absolute;
    background-color: white;
    padding: 35px;
    z-index: 100;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}




.contact h2 {
  font-size: 2rem;
    font-weight: 100;
    line-height: 1.2;
    letter-spacing: 0px;
    margin-bottom: 3rem;
}

.contact .contact-form-label {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;

  color: var(--dark-blue);
  text-transform: capitalize;
}

.contact .contact-form-input {
  width: 100%;
  height: 5rem;
  padding: 0;
  font-size: 1.3rem;
  color: #1E2021;
  border: none;
  border-bottom: solid #1E2021 0.1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: 'DMSans';
  margin-bottom: 0.1rem;
}

.contact .contact-form-input:focus {
  background-color: #e3e3e3 !important;
  border-bottom: solid #1E2021 0.2rem;
  margin-bottom: 0rem;
}


.contact .contact-form-input.textarea {
  font-family: 'DMSans';
  resize: none;
  padding: 1rem 0;
  overflow: hidden;
  min-height: 6rem;
  max-height: 15rem;

  max-width: 100%;
}

.contact .contact-form .bottom {
  width: 100%;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: right;
  margin-top: 2rem;
}






.contact .contact-form-input:focus {
  outline: none;


}




.contact-form .button {
  margin: 15px
}


.contact .contact-form-input.is-invalid {
  border-bottom: 1px solid #cc3d3d;
}

.contact .invalid-feedback {
  color: #cc3d3d;
}

.blog .blogpost {
  width: 70%;
  display: flex;
  padding: 0px;
}

.blog .blogpost a {
  text-decoration: none;
  color: var(--dark-blue);
}

.blog .blog-image {
  width: 300px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-image.blog-image-edit {
  border: 1px solid;
  margin-bottom: 25px;
}


.blog .blog-image img {
  height: 100%;

  margin-left: auto;
  margin-right: auto;
}


.blog .blogpost .blog-content {
  width: 70%;
  padding: 1.5rem;
}


.card.form input {

  box-sizing: border-box;
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}


.card.form .submit-button {
  cursor: pointer;
  width: 100%;
  display: block;
  background-color: #555;
  border: 0;
  color: #fff;
  border-radius: 5px;
  padding: 7px 0;
}


.blog .card.form .quill {
  color: black;
  min-height: 300px;
}

.blog .card.form .quill .ql-editor {
  min-height: 300px;
}

.blog .card.form {
  text-align: left;
}

.blog .card.form .checkbox_container {
  display: inline-flex;
  font-size: 0.9rem;
  color: black;
}

.blog .card.form .form-buttons {
  display: flex;

  margin-top: 10px;
  justify-content: space-between;
}

.blog .card.form .form-buttons .submit-button {

  width: 40%;

}

.blog .round-button {
  text-align: center;
  margin: auto;
  font-weight: 800;
  padding: 17px;
  border-radius: 5px;
  font-size: 22px;
  text-decoration: none;
  background: var(--dark-blue);
  color: white;
  display: flex;
  width: 200px;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 25px;
}

.blog .round-button:hover {
  background-color: white;
  color: var(--darker-blue);
}

.login .card.form {
  text-align: center;
}

.login .card {
  width: 30%;
}

.image-container {
  width: 400px;
  height: 600px;
  background-color: #555;
}




@media screen and (max-width: 992px) {

  .card{
    width: 85% !important;
    font-size: unset;
    padding: 1.5rem 1rem !important;
  }
  
  .header h1{
    font-size: 2.5rem;
  }

  .top-header {
    display: flex;
  }
  .top-header .menu-button {
    display: block;
  }

  .navbar ul {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .navbar ul li{
    margin-bottom: 10px!important;
  }

  .navbar {
    display: none;
  }

  .navbar .left,
  .navbar .right{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .navbar.show {
    display: block;
  }

  .navbar .logo{
    display: none;
  }

  .dropdown:focus-within~.dropdown-content {
    display: flex;
  }

  .dropdown-content {
    display: none;
    flex-direction: column;
    position: relative;
    padding: 5px;
    margin-left: 0;
    margin-top: 10px;
  }

  .dropdown-list{
    list-style-type: none;
    display: flex;
  }


  .lng-selector svg {
    stroke: white;
  }

  .lng-selector .dropdown-content {
    margin-left: 0;
  }

  .navbar a,
  .navbar .dropdown {
    cursor: pointer;
    margin: auto;
    font-size: 24px;
  }



  

  .dropdown-content a {
    font-size: 20px;
  }

  .about .devider div:first-of-type {
    margin-right: 0;
  }

  .about .devider div:last-of-type {
    margin-left: 0;
  }

 
  
  .partners img.partner_logo{
    width: 100%;
  }
  

  .partners img.partner_ilustration{
    width: 100%;
  }
  .partners img.partner_ilustration:hover{
    width: 90%;
  }
  

}


.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.center{
  justify-content: center;
    display: flex;
    padding: 100px;
}